$(document).foundation({
    equalizer : {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true
    }
});

$.cookieBar(
    options =
    {
        message: 'By using this website you are agreeing to our cookie policy',
        acceptButton: false,
        policyButton: true,
        policyText: 'Cookie Policy',
        policyURL: '/privacy-policy/',
        autoEnable: true,
        acceptOnContinue: true,
        acceptOnScroll: true,
        expireDays: 365,
        renewOnVisit: false
    }
);


$(window).on('load', function() {

    //  Homepage Grid Navigation Isotope
    var $grid = $('.block__grid--navigation').isotope({
        itemSelector: '.grid--item',
        layoutMode: 'masonry',
    });


    //  News Grid Navigation Isotope
    // var $grid = $('.block__articles--grid').isotope({
    //     itemSelector: '.article--item',
    //     layoutMode: 'masonry',
    // });



    // ****************************************
    //  Stores Grid Navigation Isotope
    // ****************

    var $storeGrid = $('.block__stores--list').isotope({
        itemSelector: '.store__item',
        layoutMode: 'fitRows',
        animateResizeContainer: false,
    });

    // bind filter on select change
    $('.store--filter__button').on( 'click', function() {
        var $this = $(this);
        // get filter value from option value
        var filterValue = $this.data('filter');
        $storeGrid.isotope({ filter: filterValue });
    });

    // bind filter on select change
    $('.stores--list__filter').on( 'change', function() {
        // get filter value from option value
        var filterValue = this.value;
        $storeGrid.isotope({ filter: filterValue });
    });

    // ****************
    //  End of Stores Grid Isotope
    // ****************************************

});


// Shopping Centre 360 Tour
// $("#tourModal").html('<object data="http://your-website-domain"/>');

// $(document).ready(function() {
    //  Stores Carousel
    // $('.stores--slider').slick({
    //     arrows: true,
    //     slidesToShow: 10,
    //     slidesToScroll: 3,
    //     infinite: true,
    //     responsive: [
    //         {
    //             breakpoint: 640,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1
    //             }
    //         }
    //     ]
    // });


    //  Stores Carousel
    // $('.related--articles__slider').slick({
    //     arrows: true,
    //     slidesToShow: 5,
    //     centerMode: true,
    //     centerPadding: '60px',
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //             breakpoint: 640,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //                 centerPadding: '40px',
    //             }
    //         }
    //     ]
    // });


    // $(".store__item").click(function(event) {
    //     event.preventDefault();
    //     $(".store__item").removeClass('active');
    //     $(".store--details").removeClass('active');
    //     $(".stores__item--details").addClass('open');
    //     $("body").addClass('store--details__open');
    //
    //
    //     var storeId = $(this).data('store-id');
    //     $(".store--details[data-store-id="+ storeId +"]").addClass("active");
    //
    //
    //     $("body").animate({scrollTop: $(".stores__item--details.open").offset().top - 50 }, 'slow');
    //     $(this).addClass("active");
    // });

    // $("#store--details__container").hammer().bind("swipeleft", function()
    // {
    //     $(".store__item").removeClass('active');
    //     $(".store--details.active").removeClass('active').next().addClass("active");
    //
    // });
    //
    // $("#store--details__container").hammer().bind("swiperight", function()
    // {
    //     $(".store__item").removeClass('active');
    //     $(".store--details.active").removeClass('active').prev().addClass("active");
    //
    // });


    // $(".stores__item--details__close").click(function(event) {
    //     event.preventDefault();
    //     $(".stores__item--details").removeClass("open");
    //     $(".store__item").removeClass("active");
    //     $("body").removeClass('store--details__open');
    // });
    //
    // $(".floor--plan__button").click(function(event) {
    //     event.preventDefault();
    //     $(".store--details").toggleClass("active");
    //     $(".store--location").toggleClass("active");
    // });

    // $(".show-for-featured-only").text(function(index, currentText) {
    //     return currentText.substr(0, 75);
    // });
// });


function m40_socialShare(url, winWidth, winHeight)
{
    var winTop = (screen.height / 2) - (winHeight / 2);
    var winLeft = (screen.width / 2) - (winWidth / 2);
    window.open(url, 'm40_sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
    return false;

}

$(document).foundation({
  equalizer : {
    // Specify if Equalizer should make elements equal height once they become stacked.
    equalize_on_stack: true
  }
});
