$(window).load(function()
{
    $(".js-slick").slick({
        dots: true,
        autoplay: true,
        autoplaySpeed: 6000,
        fade: true,
        cssEase: 'linear' 
    });
 
    $(".js-background-video").each(function()
    {
        var playerContainer = $(this);

        if($(this).hasClass('youtube'))
        {
            $(this).YTPlayer({
                videoId: $(this).data('video-id'),
                playerVars: {
                    playlist: $(this).data('video-id'),
                    loop: 1
                },
                fitToBackground: false,
                width: 	$(this).width(),
                callback: function() {
                    $(playerContainer).addClass('js-remove-background')
                }
            });
        };

    });

    $('.block__testimonials-wrapper').slick({
       slidesToShow: 2,
        slidesToScroll: 2
    });

});